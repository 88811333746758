// Fonction de survie pour la loi de Weibull
function rWeibull(t, shape, scale) {
  // R(t) = exp(- (t / scale)^shape)
  return Math.exp(-Math.pow(t / scale, shape));
}

// Fonction de survie pour la loi de Gompertz
function rGompertz(t, shape, scale) {
  // R(t) = exp(-shape * (exp(scale * t) - 1))
  return Math.exp(-shape * (Math.exp(scale * t) - 1));
}

// Calcul du paramètre scale (échelle) pour Weibull à partir d'un point
function resolveScaleWeibull(t1, R1, shape) {
  // scale = t1 / (-ln(R1)^(1/shape))
  return t1 / Math.pow(-Math.log(R1), 1 / shape);
}

// Calcul du paramètre shape (forme) pour Weibull à partir de deux points
function resolveShapeWeibull(t1, t2, R1, R2) {
  // shape = log(log(R1) / log(R2)) / log(t1 / t2)
  return Math.log(Math.log(R1) / Math.log(R2)) / Math.log(t1 / t2);
}

// Fonction de taux de panne pour la loi de Weibull
function failureRateWeibull(t, shape, scale) {
  // h(t) = shape / scale * (t / scale)^(shape - 1)
  return (shape / scale) * Math.pow(t / scale, shape - 1);
}

// Fonction de taux de survie pour la loi de Gompertz
function failureRateGompertz(t, shape, scale) {
  // h(t) = shape * scale * exp(scale * t)
  return shape * scale * Math.exp(scale * t);
}

function generateSurvivalData(modeFiabilite, echelle, forme) {
  // R(t) = exp(- (t / scale)^shape)
  const data = [];
  const tMax = Math.min(Math.max(echelle * 2, 10), 100);
  const step = 0.1; // More granular steps

  for (let t = 0; t <= tMax; t += step) {
    let R = 0;
    if (modeFiabilite === 'Gompertz') {
      R = rGompertz(t, forme, echelle);
    } else if (modeFiabilite === 'Weibull') {
      R = rWeibull(t, forme, echelle);
    }
    data.push({ t: parseFloat(t.toFixed(2)), R });
  }
  return data;
}

function generateFailureData(modeFiabilite, echelle, forme) {
  // h(t) = shape / scale * (t / scale)^(shape - 1)
  const data = [];
  const tMax = Math.min(Math.max(echelle * 2, 10), 100);
  const step = 0.1;

  for (let t = 0; t <= tMax; t += step) {
    let failureRate = 0;
    if (modeFiabilite === 'Gompertz') {
      failureRate = failureRateGompertz(t, forme, echelle);
    } else if (modeFiabilite === 'Weibull') {
      failureRate = failureRateWeibull(t, forme, echelle);
    }
    data.push({ t: parseFloat(t.toFixed(2)), R: failureRate });
  }
  return data;
}

function computeInitialPoints(modeFiabilite, echelle, forme) {
  if (!echelle || !forme) return null;

  let t1, t2, R1, R2;
  const MIN_PROBABILITY = 0.01; // Increased from 0.001 to stay away from 0
  const MAX_PROBABILITY = 0.99; // Stay away from 1

  if (modeFiabilite === 'Weibull') {
    // Start with reasonably spaced time values
    t1 = Math.max(echelle * 0.2, 0.1);
    t2 = Math.max(echelle * 0.6, t1 + 0.1); // Ensure t2 > t1
    R1 = rWeibull(t1, forme, echelle);
    R2 = rWeibull(t2, forme, echelle);

    // Adjust if probabilities are outside bounds
    while ((R1 <= MIN_PROBABILITY || R1 >= MAX_PROBABILITY) && t1 > 0.1) {
      t1 = t1 * (R1 <= MIN_PROBABILITY ? 0.5 : 2);
      R1 = rWeibull(t1, forme, echelle);
    }
    while ((R2 <= MIN_PROBABILITY || R2 >= MAX_PROBABILITY) && t2 > t1 + 0.1) {
      t2 = t2 * (R2 <= MIN_PROBABILITY ? 0.5 : 2);
      R2 = rWeibull(t2, forme, echelle);
    }
  } else if (modeFiabilite === 'Gompertz') {
    t1 = Math.max(0.5 / echelle, 0.1);
    t2 = Math.max(1 / echelle, t1 + 0.1); // Ensure t2 > t1
    R1 = rGompertz(t1, forme, echelle);
    R2 = rGompertz(t2, forme, echelle);

    // Adjust if probabilities are outside bounds
    while ((R1 <= MIN_PROBABILITY || R1 >= MAX_PROBABILITY) && t1 > 0.1) {
      t1 = t1 * (R1 <= MIN_PROBABILITY ? 0.5 : 2);
      R1 = rGompertz(t1, forme, echelle);
    }
    while ((R2 <= MIN_PROBABILITY || R2 >= MAX_PROBABILITY) && t2 > t1 + 0.1) {
      t2 = t2 * (R2 <= MIN_PROBABILITY ? 0.5 : 2);
      R2 = rGompertz(t2, forme, echelle);
    }
  }

  // Ensure final values are within bounds
  R1 = Math.min(Math.max(R1, MIN_PROBABILITY), MAX_PROBABILITY);
  R2 = Math.min(Math.max(R2, MIN_PROBABILITY), MAX_PROBABILITY);

  // Ensure t2 > t1 > 0
  t1 = Math.max(t1, 0.1);
  t2 = Math.max(t2, t1 + 0.1);

  return {
    t1: t1.toFixed(2),
    t2: t2.toFixed(2),
    R1: R1.toFixed(4),
    R2: R2.toFixed(4),
  };
}

// Évaluation de b pour l'optimisation
function evalB(b, t1, t2, R1, R2) {
  const A = Math.exp(b * t1) - 1;
  const B = Math.exp(b * t2) - 1;
  const C = Math.log(R1);
  const D = Math.log(R2);

  return b === 0 ? t1 / t2 - C / D : A / B - C / D;
}

// Évaluation de la dérivée de b pour la recherche de solution
function evalBPrime(b, t1, t2) {
  if (b === 0) return 0.5 * (t1 / t2) * (t1 - t2);
  const A = Math.exp(b * t1) - 1;
  const APrime = t1 * Math.exp(b * t1);
  const B = Math.exp(b * t2) - 1;
  const BPrime = t2 * Math.exp(b * t2);

  return (APrime * B - A * BPrime) / Math.pow(B, 2);
}

// Résolution de la valeur de scale (b) en utilisant la méthode de Newton-Raphson
function resolveScaleGompertz(t1, t2, R1, R2) {
  if (t2 <= t1) throw new Error('t1 must be smaller than t2');
  if (t1 / t2 <= Math.log(R1) / Math.log(R2)) throw new Error('No solution with a positive scale can be found');

  const tol = 1e-6;
  const nIterMax = 1000;
  let b = 0.01; // Initialisation avec une petite valeur pour éviter la division par zéro

  for (let i = 0; i < nIterMax; i++) {
    const fB = evalB(b, t1, t2, R1, R2);
    if (Math.abs(fB) < tol) break;

    const fBPrime = evalBPrime(b, t1, t2);
    if (Math.abs(fBPrime) < tol) throw new Error('Cannot find a solution due to too small derivative value.');

    b = b - fB / fBPrime;
  }

  return b;
}

// Résolution de la forme (shape) pour Gompertz
function resolveShapeGompertz(b, t1, R1) {
  return -Math.log(R1) / (Math.exp(b * t1) - 1);
}

export {
  rWeibull,
  rGompertz,
  resolveScaleWeibull,
  resolveShapeWeibull,
  failureRateGompertz,
  failureRateWeibull,
  generateSurvivalData,
  generateFailureData,
  computeInitialPoints,
  resolveScaleGompertz,
  resolveShapeGompertz,
};
