import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import EditableTable from '../../components/EditableTable';
import GenericAutoCompleteInput from '../../components/GenericAutoCompleteInput';
import { useSousGisement } from './hooks/SousGisementHook';

const SousGisementsTab = ({ selectedSousGisement, setSelectedSousGisement, selectedGisement, editMode }) => {
  const { sousGisement, tableNamesMapping, selectedClassActifAttrs } = useSousGisement(selectedGisement);
  const [sousGisementOptions, setSousGisementOptions] = useState(
    Array.from(new Set(sousGisement?.map((sg) => sg.Identifiant)))
  );

  const handleAddSousGisement = (newSousGisement) => {
    setSelectedSousGisement(newSousGisement);
    setSousGisementOptions((prevOptions) => [...prevOptions, newSousGisement]);
  };

  const calculateClassActifAttributes = (classeActifAttrs) => {
    if (!classeActifAttrs) return;
    const attributsList = classeActifAttrs
      .filter(
        (attr) =>
          ![
            'AgeApparent',
            'AgeApparentConnu',
            'DateDerniereAction',
            'DateDerniereInspection',
            'DateInstallation',
            'FamilleEquipement',
            'id',
            'Identifiant',
          ].includes(attr)
      )
      .map((attr) => ({ label: attr, value: attr }));
    return attributsList;
  };

  const columns = [
    {
      field: 'Attribut',
      label: 'Attribut',
      inputType: 'select',
      options: calculateClassActifAttributes(selectedClassActifAttrs),
    },
    {
      field: 'Operateur',
      label: 'Opérateur',
      inputType: 'select',
      options: [
        { label: 'IN', value: 'IN' },
        { label: '<', value: 'LT' },
        { label: '≤', value: 'LEQ' },
        { label: '>', value: 'GT' },
        { label: '≥', value: 'GEQ' },
      ],
    },
    { field: 'Valeurs', label: 'Valeur' },
  ];

  return (
    <Box width={'100%'}>
      <GenericAutoCompleteInput
        id="select-or-create-sous-gisement-autocomplete"
        onChange={(e, newSousGisement) => setSelectedSousGisement(newSousGisement || '')}
        onNewOption={handleAddSousGisement}
        value={selectedSousGisement || ''}
        options={sousGisementOptions ?? []}
        label="Sélectionner ou créer un sous-gisement"
        editMode={editMode}
      />
      <Controller
        name={tableNamesMapping.SousGisements}
        defaultValue={sousGisement ?? []}
        render={({ field }) => {
          const { value, onChange } = field;
          return (
            <EditableTable
              id="sous-gisement"
              columns={columns}
              rows={value?.filter((sg) => sg.Identifiant === selectedSousGisement)}
              onChange={onChange}
              disabled={!selectedSousGisement}
              notFilteredRows={value}
              filteringCol={{ field: 'Identifiant', value: selectedSousGisement }}
              editMode={editMode && !!selectedSousGisement}
            />
          );
        }}
      />
    </Box>
  );
};

SousGisementsTab.propTypes = {
  selectedSousGisement: PropTypes.string, // Peut être vide si non sélectionné
  setSelectedSousGisement: PropTypes.func.isRequired,
  selectedGisement: PropTypes.string,
  editMode: PropTypes.bool,
};

export default SousGisementsTab;
