import React, { useState, useMemo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Switch,
  TextField,
} from '@mui/material';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { UPLOAD_FILE_STATUS_KEY } from '@cosmotech/ui';
import { useFamilleEquipements } from './hooks/FamilleEquipementsHook';
import {
  resolveScaleGompertz,
  resolveScaleWeibull,
  resolveShapeGompertz,
  resolveShapeWeibull,
  generateSurvivalData,
  generateFailureData,
  computeInitialPoints,
} from './typeLoi.utils';

export const updateCurveParameters = (points, modeFiabilite, setErrorMessage) => {
  if (!points) return;

  const t1 = parseFloat(points.t1);
  const t2 = parseFloat(points.t2);
  const R1 = parseFloat(points.R1);
  const R2 = parseFloat(points.R2);

  if (isNaN(t1) || isNaN(t2) || isNaN(R1) || isNaN(R2)) {
    setErrorMessage('Veuillez entrer des valeurs numériques valides pour t1, t2, R1, et R2.');
    return;
  }

  if (t1 <= 0 || t2 <= 0 || t2 <= t1) {
    setErrorMessage('Assurez-vous que t1 et t2 sont positifs avec t2 > t1.');
    return;
  }

  if (R1 <= 0 || R1 >= 1 || R2 <= 0 || R2 >= 1) {
    setErrorMessage('R1 et R2 doivent être des nombres entre 0 et 1 (exclus).');
    return;
  }

  setErrorMessage('');

  try {
    if (modeFiabilite === 'Gompertz') {
      const b = resolveScaleGompertz(t1, t2, R1, R2);
      const eta = resolveShapeGompertz(b, t1, R1);
      return { Echelle: b, Forme: eta };
    } else if (modeFiabilite === 'Weibull') {
      const eta = resolveShapeWeibull(t1, t2, R1, R2);
      const lambda = resolveScaleWeibull(t1, R1, eta);
      return { Echelle: lambda, Forme: eta };
    }
  } catch (error) {
    console.error('Erreur dans le calcul des paramètres :', error);
    setErrorMessage('Une erreur est survenue lors du calcul des paramètres. Veuillez vérifier vos entrées.');
  }
};

const CHART_MODES = {
  SURVIVAL: 'survival',
  FAILURE: 'failure',
};

const FamilleEquipements = ({ context }) => {
  const [chartMode, setChartMode] = useState(CHART_MODES.SURVIVAL);
  const [openDialog, setOpenDialog] = useState(false);
  const [points, setPoints] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const { agingLaws, equipmentFamily, savedPoints, savePoints } = useFamilleEquipements();

  const chartConfigs = useMemo(
    () => ({
      [CHART_MODES.SURVIVAL]: {
        title: 'Courbe de la fonction de survie',
        yAxisLabel: 'Probabilité de survie (R)',
        yDomain: [0, 1],
        valueLabel: 'R',
        getData: generateSurvivalData,
      },
      [CHART_MODES.FAILURE]: {
        title: 'Courbe de la fonction de taux de pannes',
        yAxisLabel: 'Taux de pannes (h)',
        yDomain: [0, 'auto'],
        valueLabel: 'h',
        getData: generateFailureData,
      },
    }),
    []
  );

  const setNewFamilleEquipementValue = useCallback(
    (value, changedValues) => {
      return value.map((fe) => {
        if (fe.Identifiant === equipmentFamily) {
          return { ...fe, ...changedValues };
        }
        return fe;
      });
    },
    [equipmentFamily]
  );

  const handleDialogClose = useCallback(() => {
    setOpenDialog(false);
    setPoints(null);
    setErrorMessage('');
  }, []);

  const updatePoint = useCallback(
    (key, value) => {
      setPoints((prevPoints) => {
        const newPoints = {
          ...(prevPoints || {}),
          [key]: value,
        };
        savePoints(newPoints);
        return newPoints;
      });
    },
    [savePoints]
  );

  const getPointValue = useCallback(
    (key) => {
      return points && points[key] !== undefined ? points[key] : '';
    },
    [points]
  );

  const defaultValue = useMemo(
    () => ({
      id: null,
      name: `FamilleEquipement.csv`,
      rows: agingLaws?.FamilleEquipement ?? [],
    }),
    [agingLaws]
  );

  const generateChartData = useCallback(
    (currentFamily) => {
      if (!currentFamily?.ModeFiabilite || !currentFamily?.Echelle || !currentFamily?.Forme) {
        return [];
      }
      return chartConfigs[chartMode].getData(currentFamily.ModeFiabilite, currentFamily.Echelle, currentFamily.Forme);
    },
    [chartMode, chartConfigs]
  );

  const generateXAxisTicks = useCallback((currentFamily) => {
    if (!currentFamily?.Echelle) return [];
    const maxTime = Math.min(Math.max(currentFamily.Echelle * 2, 10), 100);
    const stepSize = Math.ceil(maxTime / 30);
    const numSteps = Math.floor(maxTime / stepSize) + 1;
    return Array.from({ length: numSteps }, (_, i) => i * stepSize);
  }, []);

  const handleCurveParameterUpdate = useCallback(
    (value, onChange) => {
      const currentFamily = value.rows?.find((fe) => fe.Identifiant === equipmentFamily);
      const newFormeAndEchelle = updateCurveParameters(points, currentFamily?.ModeFiabilite, setErrorMessage);
      if (!newFormeAndEchelle) return;

      const newValue = setNewFamilleEquipementValue(value.rows, newFormeAndEchelle);
      onChange({ ...value, status: UPLOAD_FILE_STATUS_KEY.READY_TO_UPLOAD, rows: newValue });
      setOpenDialog(false);
    },
    [points, equipmentFamily, setNewFamilleEquipementValue]
  );

  const handleEditCurveParameters = useCallback(
    (value) => {
      const currentFamily = value.rows?.find((fe) => fe.Identifiant === equipmentFamily);
      const initialPoints =
        savedPoints || computeInitialPoints(currentFamily.ModeFiabilite, currentFamily.Echelle, currentFamily.Forme);
      setPoints(initialPoints);
      setOpenDialog(true);
    },
    [equipmentFamily, savedPoints]
  );

  if (!equipmentFamily) return <span>Aucune donnée à afficher.</span>;

  return (
    <div>
      <Controller
        name="FamilleEquipement"
        defaultValue={defaultValue}
        render={({ field }) => {
          const { value, onChange } = field;
          const handleFieldChange =
            (fieldName, parser = parseFloat) =>
            (e) => {
              const newValue = parser(e.target.value);
              const updatedValue = setNewFamilleEquipementValue(value.rows, {
                [fieldName]: newValue,
              });
              onChange({ ...value, status: UPLOAD_FILE_STATUS_KEY.READY_TO_UPLOAD, rows: updatedValue });
            };
          return (
            <>
              <Grid container spacing={2} marginTop={2}>
                <Grid item xs={4} lg={2}>
                  <TextField
                    data-cy="mode"
                    select
                    label="Mode de Fiabilité"
                    disabled={!context.editMode}
                    value={value.rows?.find((fe) => fe.Identifiant === equipmentFamily)?.ModeFiabilite}
                    onChange={handleFieldChange('ModeFiabilite', (value) => value)}
                    fullWidth
                  >
                    <MenuItem data-cy="weibull" value="Weibull">
                      Weibull
                    </MenuItem>
                    <MenuItem data-cy="gompertz" value="Gompertz">
                      Gompertz
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={4} lg={1.5}>
                  <TextField
                    data-cy="form"
                    label="Forme"
                    type="number"
                    disabled={!context.editMode}
                    value={value.rows?.find((fe) => fe.Identifiant === equipmentFamily)?.Forme}
                    onChange={handleFieldChange('Forme')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} lg={1.5}>
                  <TextField
                    data-cy="scale"
                    label="Échelle"
                    type="number"
                    disabled={!context.editMode}
                    value={value.rows?.find((fe) => fe.Identifiant === equipmentFamily)?.Echelle}
                    onChange={handleFieldChange('Echelle')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} lg={3.5}>
                  <TextField
                    data-cy="age-delta"
                    label="Age référent pour critère avec delta"
                    type="number"
                    inputProps={{ min: 0 }}
                    disabled={!context.editMode}
                    value={value.rows?.find((fe) => fe.Identifiant === equipmentFamily)?.AgeReferentDelta || ''}
                    onChange={handleFieldChange('AgeReferentDelta')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} lg={3.5}>
                  <TextField
                    data-cy="age-percentage"
                    label="Age référent pour critère avec pourcentage"
                    type="number"
                    inputProps={{ min: 0 }}
                    disabled={!context.editMode}
                    value={value.rows?.find((fe) => fe.Identifiant === equipmentFamily)?.AgeReferentPourcentage || ''}
                    onChange={handleFieldChange('AgeReferentPourcentage')}
                    fullWidth
                  />
                </Grid>
                <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="sm">
                  <DialogTitle>Modifier les points de la courbe</DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2} padding={1}>
                      <Grid item xs={6}>
                        <TextField
                          label="t1"
                          type="number"
                          inputProps={{ min: 0 }}
                          value={getPointValue('t1')}
                          onChange={(e) => updatePoint('t1', e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="R1"
                          type="number"
                          inputProps={{ step: 'any', min: 0, max: 1 }}
                          value={getPointValue('R1')}
                          onChange={(e) => updatePoint('R1', e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="t2"
                          type="number"
                          inputProps={{ min: 0 }}
                          value={getPointValue('t2')}
                          onChange={(e) => updatePoint('t2', e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="R2"
                          type="number"
                          inputProps={{ step: 'any', min: 0, max: 1 }}
                          value={getPointValue('R2')}
                          onChange={(e) => updatePoint('R2', e.target.value)}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    {errorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                      Annuler
                    </Button>
                    <Button onClick={() => handleCurveParameterUpdate(value, onChange)} color="primary">
                      Appliquer
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              {/* Bouton pour éditer les paramètres de courbe */}
              <Button
                disabled={!context.editMode}
                variant="contained"
                color="primary"
                onClick={() => handleEditCurveParameters(value)}
                style={{ marginTop: '20px' }}
              >
                Éditer les paramètres de courbes de survie
              </Button>
              {/* Commutateur pour la courbe de survie/panne */}
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                <span>Survie</span>
                <Switch
                  checked={chartMode === CHART_MODES.FAILURE}
                  onChange={(e) => setChartMode(e.target.checked ? CHART_MODES.FAILURE : CHART_MODES.SURVIVAL)}
                  color="primary"
                  inputProps={{ 'aria-label': 'switch between survival and failure mode' }}
                />
                <span>Panne</span>
              </div>

              {/* Affichage de la courbe */}
              <div style={{ marginTop: '10px' }}>
                <ResponsiveContainer width="100%" height={400} style={{ marginTop: '10px' }}>
                  <LineChart
                    data={(() => {
                      const currentFamily = value.rows?.find((fe) => fe.Identifiant === equipmentFamily);
                      return generateChartData(currentFamily);
                    })()}
                    margin={{ top: 0, right: 20, bottom: 25, left: 10 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="t"
                      label={{ value: 'Temps (t)', position: 'insideBottom', offset: -15 }}
                      tickFormatter={(tick) => tick.toFixed(0)}
                      interval={0}
                      ticks={(() => {
                        const currentFamily = value.rows?.find((fe) => fe.Identifiant === equipmentFamily);
                        return generateXAxisTicks(currentFamily);
                      })()}
                    />
                    <YAxis
                      domain={chartConfigs[chartMode].yDomain}
                      label={{
                        value: chartConfigs[chartMode].yAxisLabel,
                        angle: -90,
                        position: 'insideLeft',
                        offset: -5,
                        style: {
                          textAnchor: 'middle',
                        },
                      }}
                    />
                    <Tooltip
                      formatter={(value) => {
                        return [`${value.toFixed(2)}`, chartConfigs[chartMode].valueLabel];
                      }}
                      labelFormatter={(label) => `t : ${label.toFixed(2)}`}
                    />
                    <Legend align="center" verticalAlign="top" height={36} />
                    <Line
                      type="monotone"
                      dataKey="R"
                      name={chartConfigs[chartMode].title}
                      stroke="#8884d8"
                      strokeWidth={3}
                      dot={false}
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

FamilleEquipements.propTypes = {
  context: PropTypes.object,
};

export default FamilleEquipements;
