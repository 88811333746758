import React, { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { TwoActionsDialogService } from '../../../../services/twoActionsDialog/twoActionsDialogService';
import { WizardUtils } from '../../../../utils/WizardUtils';
import EditableTable from '../../components/EditableTable';
import GenericAutoCompleteInput from '../../components/GenericAutoCompleteInput';
import GenericMultiSelect from '../../components/GenericMultiSelect';
import { useReglesGestion } from './hooks/ReglesGestionHook';

const ReglesGestionTab = ({ selectedRegle, setSelectedRegle, selectedGisement, editMode }) => {
  const {
    reglesGestion,
    criteresDeclenchement,
    status,
    niveauDePriorisation,
    tableNamesMapping,
    familleEquipement,
    sequenceActionsOptions,
    sousGisementOptions,
    categoriesBudgetaires,
  } = useReglesGestion(selectedGisement);
  const [regleOptions, setRegleOptions] = useState(reglesGestion?.map((regle) => regle.Identifiant));
  const { setValue, getValues } = useFormContext();

  const handleAddRegle = (newRegle) => {
    setSelectedRegle(newRegle);
    setRegleOptions((prevOptions) => [...(prevOptions ?? []), newRegle]);
  };

  const generateConditionString = (tableauParametres) => {
    if (!tableauParametres || tableauParametres.length === 0) return '';

    const conditionsBySousGisement = tableauParametres.reduce((acc, param) => {
      const { SousGisement, Identifiant } = param;
      if (!acc[SousGisement]) {
        acc[SousGisement] = [];
      }
      acc[SousGisement].push(Identifiant);
      return acc;
    }, {});
    Object.keys(conditionsBySousGisement).forEach((key) => {
      conditionsBySousGisement[key] = conditionsBySousGisement[key].join(' ET ');
    });
    const conditionsMap = Object.entries(conditionsBySousGisement).map(
      ([sousGisement, criteriaList]) => `${sousGisement} ET ${criteriaList}`
    );
    return conditionsMap.map((regle, index) => (
      <Typography key={index}>
        {regle}
        {index < conditionsMap.length - 1 && <Typography>OU</Typography>}
      </Typography>
    ));
  };

  // Function to check if the AttributDeclencheur is in the disabledAttributes list
  const isDisabledAttribute = useCallback((attributDeclencheur) => {
    const disabledAttributes = ['Failure', 'QuasiFailure', 'Operating', 'AllPredeterminedProjectsDone'];
    return disabledAttributes.includes(attributDeclencheur);
  }, []);

  const columns = [
    {
      field: 'Identifiant',
      label: 'Identifiant',
    },
    {
      field: 'SousGisement',
      label: 'Sous-Gisement',
      inputType: 'select',
      options: sousGisementOptions,
    },
    {
      field: 'FamillesEquipements',
      label: "Familles d'équipements",
      inputType: 'multiSelect',
      options: familleEquipement.map((famille) => famille.Identifiant),
    },
    {
      field: 'SeuilValidation',
      label: 'Seuil de validation',
      inputType: 'number',
      min: 0,
      max: 1,
      step: 0.01,
    },
    {
      field: 'AttributDeclencheur',
      label: 'Attribut Déclencheur',
      inputType: 'select',
      options: [
        { label: 'Failure', value: 'Failure' },
        { label: 'Operating', value: 'Operating' },
        { label: 'QuasiFailure', value: 'QuasiFailure' },
        { label: 'Age', value: 'Age' },
        { label: 'AgeDuringFirstLifecycle', value: 'AgeDuringFirstLifecycle' },
        { label: 'AgeAfterFirstLifecycle', value: 'AgeAfterFirstLifecycle' },
        { label: 'State', value: 'State' },
        { label: 'Downtime', value: 'Downtime' },
        { label: 'InspectionDowntime', value: 'InspectionDowntime' },
        { label: 'AllPredeterminedProjectsDone', value: 'AllPredeterminedProjectsDone' },
        { label: 'CorporateCostOfFailure', value: 'CorporateCostOfFailure' },
        { label: 'SocietalCostOfFailure', value: 'SocietalCostOfFailure' },
        { label: 'CostOfFailure', value: 'CostOfFailure' },
        { label: 'SumCorporateCostOfFailure', value: 'SumCorporateCostOfFailure' },
        { label: 'SumSocietalCostOfFailure', value: 'SumSocietalCostOfFailure' },
        { label: 'SumCostOfFailure', value: 'SumCostOfFailure' },
        { label: 'ConsequenceQuantity.*', value: 'ConsequenceQuantity.*' },
        { label: 'SumConsequenceQuantity.*', value: 'SumConsequenceQuantity.*' },
        { label: 'PriorityScore', value: 'PriorityScore' },
      ],
    },
    {
      field: 'Operateur',
      label: 'Opérateur',
      inputType: 'select',
      options: [
        { label: '<', value: 'LT' },
        { label: '≤', value: 'LEQ' },
        { label: '>', value: 'GT' },
        { label: '≥', value: 'GEQ' },
      ],
      // Disable if AttributDeclencheur is in disabledAttributes
      disabled: (row) => isDisabledAttribute(row.AttributDeclencheur),
      clearIfDisabled: true,
    },
    {
      field: 'ValeurReference',
      label: 'Valeur Reference',
      // Disable if AttributDeclencheur is in disabledAttributes
      disabled: (row) => isDisabledAttribute(row.AttributDeclencheur),
      clearIfDisabled: true,
    },
  ];
  const calculateNewValue = (value, changedValue, field) => {
    if (!value.map((rg) => rg.Identifiant).includes(selectedRegle)) {
      return [...value, { Identifiant: selectedRegle, [field]: changedValue }];
    }
    return value.map((regle) => {
      if (regle.Identifiant === selectedRegle) return { ...regle, [field]: changedValue };
      return regle;
    });
  };
  const handleDeleteRegleGestion = async (event) => {
    if (selectedRegle) {
      event.stopPropagation();
      const dialogProps = {
        id: 'delete-regle-gestion',
        labels: {
          title: 'Supprimer la règle de gestion ?',
          body: '',
          button1: 'Annuler',
          button2: 'Supprimer',
        },
      };
      const result = await TwoActionsDialogService.openDialog(dialogProps);
      if (result === 2) setSelectedRegle('');
      return result;
    }
  };

  if (status === 'LOADING_GISEMENT_DATA')
    return <CircularProgress data-cy="selected-gisement-queries-loading-spinner" />;
  return (
    <Box width={'100%'} sx={{ maxWidth: '1200px', margin: '0 auto' }}>
      <Controller
        name={tableNamesMapping.ReglesGestion}
        defaultValue={reglesGestion ?? []}
        render={({ field }) => {
          const { value, onChange } = field;
          return (
            <>
              <Grid container sx={{ gap: 1 }}>
                <Grid item>
                  <GenericAutoCompleteInput
                    id="select-or-create-rule-autocomplete"
                    onChange={(e, newRegle) => setSelectedRegle(newRegle || '')}
                    onNewOption={handleAddRegle}
                    value={selectedRegle || ''}
                    options={regleOptions ?? []}
                    label="Sélectionner ou créer une règle de gestion"
                    editMode={editMode}
                  />
                </Grid>
                <Grid item>
                  {selectedRegle && editMode && (
                    <IconButton
                      data-cy="delete-rule-button"
                      onClick={async (event) => {
                        const response = await handleDeleteRegleGestion(event);
                        if (response === 2) {
                          const filteredValue = value.filter((rg) => rg.Identifiant !== selectedRegle);
                          if (filteredValue.length === 0) {
                            onChange([
                              {
                                Identifiant: null,
                                TypePriorisation: null,
                                NiveauDePriorisation: null,
                                AnneeDebut: null,
                                AnneeFin: null,
                                CategoriesBudgetaires: null,
                                SequenceActions1: null,
                                SequenceActions2: null,
                                NbIterationSequenceActions1: null,
                              },
                            ]);
                          } else onChange(filteredValue);

                          setRegleOptions(filteredValue.map((rg) => rg.Identifiant));
                          const critDValue = getValues(tableNamesMapping.CriteresDeclenchement);
                          setValue(
                            tableNamesMapping.CriteresDeclenchement,
                            critDValue
                              ? critDValue.filter((row) => row.RegleGestion !== selectedRegle)
                              : criteresDeclenchement.filter((row) => row.RegleGestion !== selectedRegle)
                          );
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Grid>

                <Grid item sx={{ flexGrow: 1 }}></Grid>
              </Grid>
              <Grid container spacing={2} marginTop={2}>
                <Grid item xs={6}>
                  <FormControl sx={{ minWidth: '100%' }}>
                    <InputLabel id="type-priorisation">Type de priorisation</InputLabel>
                    <Select
                      data-cy="priorisation-type"
                      disabled={!editMode || !selectedRegle}
                      label="Type de priorisation"
                      labelId="type-priorisation"
                      value={value?.find((regle) => regle.Identifiant === selectedRegle)?.TypePriorisation || ''}
                      onChange={(e) => {
                        const changedValue = e.target.value;
                        onChange(calculateNewValue(value, changedValue, 'TypePriorisation'));
                      }}
                      fullWidth
                    >
                      <MenuItem data-cy="operational-priorisation-type" key={'operational'} value={'Operational'}>
                        Operational
                      </MenuItem>
                      <MenuItem data-cy="tactical-priorisation-type" key={'tactical'} value={'Tactical'}>
                        Tactical
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl sx={{ minWidth: '100%' }}>
                    <InputLabel id="niveau-priorisation">Niveau de priorisation</InputLabel>
                    <Select
                      data-cy="priorisation-level"
                      label="niveau-priorisation"
                      labelId="Niveau de priorisation"
                      disabled={!editMode || !selectedRegle}
                      value={value?.find((regle) => regle.Identifiant === selectedRegle)?.NiveauDePriorisation || ''}
                      onChange={(e) => {
                        const changedValue = e.target.value;
                        onChange(calculateNewValue(value, changedValue, 'NiveauDePriorisation'));
                      }}
                      fullWidth
                    >
                      {niveauDePriorisation.length > 0 ? (
                        niveauDePriorisation.map((np) => (
                          <MenuItem data-cy={`${np}-priorisation-level`} key={np} value={np}>
                            {np}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key="DefautNiveauPriorisation" value="DefautNiveauPriorisation">
                          DefautNiveauPriorisation
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Année de Début"
                    type="number"
                    disabled={!editMode || !selectedRegle}
                    value={value?.find((regle) => regle.Identifiant === selectedRegle)?.AnneeDebut || ''}
                    onChange={(e) => {
                      const changedValue = e.target.value;
                      onChange(calculateNewValue(value, changedValue, 'AnneeDebut'));
                    }}
                    fullWidth
                    inputProps={{ 'data-cy': 'rule-start-year', min: 1900, max: 5000 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Année de Fin"
                    type="number"
                    disabled={!editMode || !selectedRegle}
                    value={value?.find((regle) => regle.Identifiant === selectedRegle)?.AnneeFin || ''}
                    onChange={(e) => {
                      const changedValue = e.target.value;
                      onChange(calculateNewValue(value, changedValue, 'AnneeFin'));
                    }}
                    fullWidth
                    inputProps={{ 'data-cy': 'rule-end-year', min: 1900, max: 5000 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <GenericMultiSelect
                    disabled={!editMode || !selectedRegle}
                    dataCy="budget-categories"
                    options={categoriesBudgetaires}
                    value={[
                      ...new Set(
                        value
                          ?.find((regle) => regle.Identifiant === selectedRegle)
                          ?.CategoriesBudgetaires?.split('|')
                          .filter(Boolean) || []
                      ),
                    ]}
                    onChange={(_, newValue) => {
                      const changedValue = [...new Set(newValue.filter(Boolean))].join('|');
                      onChange(calculateNewValue(value, changedValue, 'CategoriesBudgetaires'));
                    }}
                    label="Catégories Budgétaires"
                  />
                </Grid>
                <Grid container spacing={2} width={'100%'} margin={'auto'}>
                  <Grid item xs={5}>
                    <FormControl sx={{ minWidth: '100%' }}>
                      <InputLabel id="action-principale">Séquence d&apos;Action Principale</InputLabel>
                      <Select
                        data-cy="main-action-sequence"
                        label="Séquence d'Action Principale"
                        labelId="action-principale"
                        disabled={!editMode || !selectedRegle}
                        value={value?.find((regle) => regle.Identifiant === selectedRegle)?.SequenceActions1 || ''}
                        onChange={(e) => {
                          const changedValue = e.target.value;
                          onChange(calculateNewValue(value, changedValue, 'SequenceActions1'));
                        }}
                        fullWidth
                      >
                        {sequenceActionsOptions.map((sa) => (
                          <MenuItem data-cy={`${sa}-main-action`} key={sa} value={sa}>
                            {sa}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl sx={{ minWidth: '100%' }}>
                      <InputLabel id="action-secondaire">Séquence d&apos;Action Secondaire</InputLabel>
                      <Select
                        data-cy="secondary-action-sequence"
                        label="Séquence d'Action Secondaire"
                        labelId="action-secondaire"
                        disabled={!editMode || !selectedRegle}
                        value={value?.find((regle) => regle.Identifiant === selectedRegle)?.SequenceActions2 || ''}
                        onChange={(e) => {
                          const changedValue = e.target.value;
                          const currentRegle = value.find((regle) => regle.Identifiant === selectedRegle);

                          let updatedValue = calculateNewValue(value, changedValue, 'SequenceActions2');

                          // handle NbIterationSequenceActions1
                          if (changedValue) {
                            if (currentRegle && !currentRegle.NbIterationSequenceActions1) {
                              updatedValue = calculateNewValue(updatedValue, 1, 'NbIterationSequenceActions1');
                            }
                          } else {
                            // when clearing SequenceAction2
                            updatedValue = calculateNewValue(updatedValue, undefined, 'NbIterationSequenceActions1');
                          }

                          onChange(updatedValue);
                        }}
                        fullWidth
                      >
                        <MenuItem data-cy="secondary-action-" key="none" value="">
                          Aucune
                        </MenuItem>
                        {sequenceActionsOptions.map((sa) => (
                          <MenuItem data-cy={`${sa}-secondary-action`} key={sa} value={sa}>
                            {sa}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="Nombre d'itérations Séquence Action Principale"
                      type={'number'}
                      disabled={
                        !editMode ||
                        !selectedRegle ||
                        !value.find((regle) => regle.Identifiant === selectedRegle)?.SequenceActions2
                      }
                      value={
                        value?.find((regle) => regle.Identifiant === selectedRegle)?.NbIterationSequenceActions1 || 1
                      }
                      onChange={(e) => {
                        const changedValue = e.target.value;
                        onChange(calculateNewValue(value, changedValue, 'NbIterationSequenceActions1'));
                      }}
                      fullWidth
                      inputProps={{ 'data-cy': 'main-sequence-iterations', 'aria-valuemin': 1, min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={WizardUtils.isValueTruthy(
                            value?.find((regle) => regle.Identifiant === selectedRegle)?.ReevaluationAnnuelleCriteres
                          )}
                          onChange={(e) => {
                            const changedValue = e.target.checked;
                            onChange(calculateNewValue(value, changedValue, 'ReevaluationAnnuelleCriteres'));
                          }}
                          disabled={!editMode || !selectedRegle}
                          inputProps={{ 'data-cy': 'annual-reevalutaion' }}
                        />
                      }
                      label="Réévaluation Annuelle des Critères"
                    />
                  </Grid>
                  <Grid item sx={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={WizardUtils.isValueTruthy(
                            value?.find((regle) => regle.Identifiant === selectedRegle)?.Desactiver
                          )}
                          onChange={(e) => {
                            const changedValue = e.target.checked;
                            onChange(calculateNewValue(value, changedValue, 'Desactiver'));
                          }}
                          disabled={!editMode || !selectedRegle}
                          inputProps={{ 'data-cy': 'deactivate-rule' }}
                        />
                      }
                      label="Désactiver"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        }}
      />

      <Controller
        name={tableNamesMapping.CriteresDeclenchement}
        defaultValue={criteresDeclenchement ?? []}
        render={({ field }) => {
          const { value, onChange } = field;
          return (
            <>
              <Box sx={{ marginTop: 4 }}>
                <EditableTable
                  id="triggering-criteria"
                  columns={columns}
                  rows={selectedRegle ? (value?.filter((row) => row.RegleGestion === selectedRegle) ?? []) : []}
                  editMode={editMode && !!selectedRegle}
                  onChange={onChange}
                  notFilteredRows={value}
                  filteringCol={{ field: 'RegleGestion', value: selectedRegle }}
                />
              </Box>
              <Box sx={{ marginTop: 2 }}>
                <h3>Condition de Déclenchement</h3>
                <div>
                  {selectedRegle
                    ? generateConditionString(value?.filter((critd) => critd.RegleGestion === selectedRegle))
                    : ''}
                </div>
              </Box>
            </>
          );
        }}
      />
    </Box>
  );
};

ReglesGestionTab.propTypes = {
  selectedRegle: PropTypes.string,
  setSelectedRegle: PropTypes.func.isRequired,
  selectedGisement: PropTypes.string.isRequired,
  editMode: PropTypes.bool,
};

ReglesGestionTab.defaultProps = {
  editMode: true,
};

export default ReglesGestionTab;
