import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Checkbox, TextField } from '@mui/material';

const GenericMultiSelect = ({ disabled, options, value, onChange, label, placeholder, dataCy }) => {
  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      disabled={disabled}
      data-cy={dataCy}
      options={options}
      value={value}
      onChange={onChange}
      renderInput={(params) => <TextField {...params} label={label} placeholder={placeholder} />}
      renderOption={(props, option, { selected }) => (
        <li {...props} data-cy={option}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {option}
        </li>
      )}
    />
  );
};

GenericMultiSelect.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  dataCy: PropTypes.string,
};

export default GenericMultiSelect;
