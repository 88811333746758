// Génère une couleur distincte en HSL pour les gisements
import { isValid, lastDayOfYear, startOfYear, isAfter, isBefore } from 'date-fns';
import { WizardUtils } from '../../../utils/WizardUtils';

function generateDistinctColor(index, total) {
  const hue = 180 + (((index * 120) / total) % 120); // Limite la gamme des couleurs entre 180 et 300 (bleus/violets)
  const saturation = 60; // Saturation modérée pour les gisements
  const lightness = 65; // Luminosité légèrement plus élevée pour une palette agréable
  return { hue, saturation, lightness };
}

// Initialise les tâches (gisements et règles de gestion) dynamiquement à partir du JSON
export function initTasks(technicalPolicy) {
  if (!technicalPolicy?.gisements || Object.keys(technicalPolicy.gisements).length === 0) return;
  const tasks = [];
  const gisements = Object.keys(technicalPolicy.gisements);
  const numberOfGisements = gisements.length;

  gisements.forEach((gisement, i) => {
    const gisementId = `Gisement${i + 1}`;

    // Générer une couleur unique pour le gisement
    const gColor = generateDistinctColor(i, numberOfGisements);
    const gisementBackgroundColor = `hsl(${gColor.hue}, ${gColor.saturation}%, ${gColor.lightness}%)`;

    let gisementStart = null;
    let gisementEnd = null;

    // Créer la tâche pour le gisement (parent task)
    tasks.push({
      id: gisementId,
      name: gisement,
      start: new Date(),
      end: new Date(),
      type: 'project',
      progress: 0,
      hideChildren: false,
      displayOrder: i + 1,
      styles: {
        backgroundColor: gisementBackgroundColor,
        progressColor: 'transparent',
      },
    });

    // Ajouter les règles de gestion (child tasks) pour chaque gisement
    technicalPolicy.gisements[gisement]?.ReglesGestion?.forEach((regle, j) => {
      if (regle.length !== 0) {
        const taskId = `Task${i + 1}_${j + 1}`;
        const startYear = regle.AnneeDebut || regle.AnneeFin || new Date().getFullYear();
        const endYear = regle.AnneeFin || regle.AnneeDebut || new Date().getFullYear();

        // Check if date range and values are valid
        const isInvalidDateRange = startYear > endYear || regle.AnneeDebut === '' || regle.AnneeFin === '';
        // Do not change Date(year, 0, 1) and Date(year, 11, 31) to startOfYear and lastDayOfYear
        // if another month is used in the future like february, the valid end date should change
        const hasValidDates =
          isValid(new Date(startYear, 0, 1)) &&
          isValid(new Date(endYear, 11, 31)) &&
          isValid(new Date(endYear, 0, 1)) &&
          isValid(new Date(startYear, 11, 31));

        // Set dates based on validation
        const regleStart = hasValidDates ? new Date(Math.min(startYear, endYear), 0, 1) : startOfYear(new Date());
        const regleEnd = hasValidDates ? new Date(Math.max(startYear, endYear), 11, 31) : lastDayOfYear(new Date());

        // Mark as disabled if dates are invalid or explicitly disabled
        const isDisabled = WizardUtils.isValueTruthy(regle?.Desactiver) || isInvalidDateRange;

        // Ajuster la couleur des règles
        const ruleBackgroundColor = isDisabled
          ? `${isInvalidDateRange ? '#FF0000' : '#E0E0E0'}`
          : `hsl(${gColor.hue}, ${gColor.saturation}%, ${gColor.lightness + 10}%)`;

        // Add warning to name if dates are invalid
        const taskName = isDisabled
          ? `${regle.Identifiant} (${isInvalidDateRange ? 'dates invalides' : 'désactivée'})`
          : regle.Identifiant;

        // Mettre à jour la date de début et de fin du gisement en fonction des règles de gestion
        if (!gisementStart || isBefore(regleStart, gisementStart)) {
          gisementStart = regleStart;
        }
        if (!gisementEnd || isAfter(regleEnd, gisementEnd)) {
          gisementEnd = regleEnd;
        }

        tasks.push({
          id: taskId,
          name: taskName,
          start: regleStart,
          end: regleEnd,
          type: 'task',
          progress: 0,
          project: gisementId,
          displayOrder: i + 1,
          styles: {
            backgroundColor: ruleBackgroundColor,
            progressColor: 'transparent',
          },
        });
      }
    });

    // Mettre à jour les dates du gisement après avoir analysé toutes ses règles
    const gisementTask = tasks.find((task) => task.id === gisementId);
    if (gisementTask && gisementStart && gisementEnd) {
      gisementTask.start = gisementStart;
      gisementTask.end = gisementEnd;
    }
  });
  return tasks;
}
