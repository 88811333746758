import { t } from 'i18next';
import { all, call, takeEvery, put, select } from 'redux-saga/effects';
import { Api } from '../../../../services/config/Api';
import { DATASTORE_ACTIONS } from '../../../commons/DatastoreConstants';
import { dispatchSetApplicationErrorMessage } from '../../../dispatchers/app/ApplicationDispatcher';

const getWorkspaceId = (state) => state.workspace.current?.data?.id;
const getOrganizationId = (state) => state.organization.current?.data?.id;

/*
Action parameters:
- files: list of objects with these properties:
  - data: File object representing the file content & name
  - path: path of file when uploaded as a workspace file
*/
export function* uploadDatastoreFiles(action) {
  const workspaceId = yield select(getWorkspaceId);
  const organizationId = yield select(getOrganizationId);

  try {
    const files = action.files;
    yield all(
      files.map((file) =>
        call(Api.Workspaces.uploadWorkspaceFile, organizationId, workspaceId, file.data, true, file.path)
      )
    );
    yield put({
      type: DATASTORE_ACTIONS.ADD_WORKSPACE_FILE_PATHS,
      workspaceFilePaths: files.map((file) => `${file.path}${file.data.name}`),
    });
    yield put({ type: DATASTORE_ACTIONS.SET_LOADING, loading: false });
  } catch (error) {
    console.error(error);
    yield put(
      dispatchSetApplicationErrorMessage(
        error,
        t(
          'commoncomponents.datastore.errors.cannotUploadDatastoreFiles',
          'A problem occurred when uploading datastore workspace files.'
        )
      )
    );
  }
}

function* uploadDatastoreFilesSaga() {
  yield takeEvery(DATASTORE_ACTIONS.TRIGGER_SAGA_UPLOAD_DATASTORE_FILES, uploadDatastoreFiles);
}

export default uploadDatastoreFilesSaga;
