import React, { useCallback, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import EditableTable from '../../components/EditableTable';
import { useNiveauDePriorisationTab } from '../tabs/hooks/NiveauDePriorisationHook';

const labels = {
  niveauDePriorisation: 'Niveaux de priorisation',
  sauvegarder: 'Sauvegarder',
  annuler: 'Annuler',
  fermer: 'Fermer',
  suivant: 'Suivant',
};

export const NiveauPriorisationDialog = (props) => {
  const { editMode, open, setOpen, saveData } = props;
  const { sortedNiveauDePriorisation } = useNiveauDePriorisationTab();
  const methods = useForm({ defaultValues: {}, mode: 'onChange' });
  const { dirtyFields } = methods.formState;
  const reset = methods.reset;
  const isDirty = Object.keys(dirtyFields ?? {}).length !== 0;

  const save = useCallback(() => {
    const values = methods.getValues();
    const reset = methods.reset;
    saveData(values, reset);
  }, [methods, saveData]);

  const handleDiscardChanges = useCallback(() => {
    reset({});
    setOpen(false);
  }, [reset, setOpen]);

  const niveauDePriorisationRows = useMemo(() => {
    if (sortedNiveauDePriorisation?.length === 0)
      return [
        {
          id: 'defaultNiveauDePriorisation',
          Identifiant: 'DefautNiveauPriorisation',
          RangPriorisation: 1,
          SchemaPriorisation: 'RiskBased',
        },
      ];
    return sortedNiveauDePriorisation;
  }, [sortedNiveauDePriorisation]);

  const columns = [
    {
      field: 'Identifiant',
      label: 'Niveau',
    },
    {
      field: 'RangPriorisation',
      label: 'Rang de priorisation',
      inputType: 'number',
    },
    {
      field: 'SchemaPriorisation',
      label: 'Schema de priorisation',
      inputType: 'select',
      options: ['RiskBased', 'ProbabilityBased', 'ConsequenceBased', 'OpportunityBased'],
    },
  ];

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <DialogTitle>{labels.niveauDePriorisation}</DialogTitle>
      <DialogContent>
        <Box width={'100%'}>
          <FormProvider {...methods} key={`form-technical-policy-wizard-niveau-priorisation`}>
            <Controller
              name="NiveauDePriorisation"
              defaultValue={niveauDePriorisationRows ?? []}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <EditableTable
                    id="NiveauDePriorisation"
                    columns={columns}
                    rows={value ?? []}
                    onChange={onChange}
                    editMode={editMode}
                  />
                );
              }}
            />
          </FormProvider>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between', gap: 2, m: 2 }}>
          <Button
            data-cy="close-niveau-priorisation-dialog-button"
            onClick={handleDiscardChanges}
            variant="outlined"
            color="secondary"
          >
            {isDirty ? labels.annuler : labels.fermer}
          </Button>
          {editMode && (
            <Button
              data-cy="save-technical-priorisation-button"
              disabled={!isDirty}
              onClick={save}
              variant="contained"
              color="primary"
            >
              {labels.sauvegarder}
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

NiveauPriorisationDialog.propTypes = {
  editMode: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  saveData: PropTypes.func.isRequired,
};
