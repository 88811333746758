import React, { useCallback, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Tab,
  Tabs,
  TextField,
  Typography,
  DialogTitle,
} from '@mui/material';
import { Api } from '../../../services/config/Api';
import { SCENARIO_RUN_STATE, SCENARIO_VALIDATION_STATUS } from '../../../services/config/ApiConstants';
import { ACL_PERMISSIONS } from '../../../services/config/accessControl';
import { TwoActionsDialogService } from '../../../services/twoActionsDialog/twoActionsDialogService';
import { WizardUtils } from '../../../utils/WizardUtils';
import EditableTable from '../components/EditableTable';
import GenericAutoCompleteInput from '../components/GenericAutoCompleteInput';
import { useWizard } from './WizardHook';
// import ActionsImposeesTab from './tabs/ActionsImposeesTab';
import { NiveauPriorisationDialog } from './dialogs/NiveauPriorisationDialog';
import ReglesGestionTab from './tabs/ReglesGestionTab';
import SequencesActionsTab from './tabs/SequencesActionsTab';
import SousGisementsTab from './tabs/SousGisementsTab';

const gestionActivitesColumnsList = [
  'ReglesGestion',
  'CriteresDeclenchement',
  'Impacts',
  'Gisement',
  'ClasseActif',
  'SousGisements',
  'id',
  'CoutsEtDureesActions',
  'SequencesActions',
];

const labels = {
  sauvegardeEnCours: 'Sauvegarde en cours...',
  gestionDesActivitesWizard: 'Gestion des Activités - Wizard',
  niveauDePriorisation: 'NIVEAUX DE PRIORISATION',
  suivant: 'Suivant',
  sauvegarder: 'Sauvegarder',
  annuler: 'Annuler',
  fermer: 'Fermer',
  reglesDeGestion: 'Règles de Gestion',
  SousGisements: 'Sous-Gisements',
  sequencesActions: "Séquences d'Actions",
};

const Wizard = ({ isWizardOpen, handleCloseWizard }) => {
  const methods = useForm({ defaultValues: {}, mode: 'onChange' });
  const { dirtyFields } = methods.formState;
  const isDirty = Object.keys(dirtyFields ?? {}).length !== 0;
  const [selectedGisement, setSelectedGisement] = useState('');
  const [selectedAssetClass, setSelectedAssetClass] = useState('');

  const {
    technicalPolicyData,
    getGisementData,
    datasetId,
    organizationId,
    scenarioData,
    saveData,
    classActifs,
    classActifAttrsByGisement,
    setNewTableNamesMapping,
    userPermissionsOnCurrentScenario,
    updateGisementData,
    status,
  } = useWizard(selectedGisement);

  const generateGestionActivitesRow = () => {
    const newRow = {};
    gestionActivitesColumnsList.forEach((column) => {
      if (['Gisement', 'id'].includes(column)) {
        newRow[column] = selectedGisement;
      } else if (column === 'ClasseActif') newRow[column] = selectedAssetClass;
      else {
        newRow[column] = selectedGisement + column;
      }
    });
    return newRow;
  };

  const saveWizardData = () => {
    const values = methods.getValues();
    const reset = methods.reset;
    if (selectedGisement && !Object.keys(technicalPolicyData?.gisements).includes(selectedGisement)) {
      const newGestionActivites = generateGestionActivitesRow();
      setNewTableNamesMapping(scenarioData.id, selectedGisement, newGestionActivites);
      updateGisementData(
        scenarioData.id,
        [{ Attrs: selectedAssetClassAttrsList, NiveauCout: selectedAssetClassNiveauCout }],
        'ClasseActif',
        selectedGisement
      );
      saveData(values, reset, newGestionActivites);
    } else saveData(values, reset);
  };
  const gisementsList = useMemo(() => {
    return Object.keys(technicalPolicyData?.gisements ?? {});
  }, [technicalPolicyData]);

  const [gisementOptions, setGisementOptions] = useState(gisementsList);

  const assetClassByGisement = useMemo(() => {
    return technicalPolicyData?.gisements[selectedGisement]?.tableNamesMapping?.ClasseActif;
  }, [selectedGisement, technicalPolicyData]);

  const [selectedAssetClassAttrsList, setSelectedAssetClassAttrsList] = useState([]);
  const [selectedAssetClassNiveauCout, setSelectedAssetClassNiveauCout] = useState([]);
  const [loadingClassActifAttributs, setLoadingClassActifAttributs] = useState(false);
  const classActifsList = useMemo(() => {
    return classActifs?.map((classActif) => classActif.NiveauHierarchique);
  }, [classActifs]);

  const calculateClassActifAttributes = (classeActifAttrs) => {
    if (!classeActifAttrs) return;
    const attributsList = classeActifAttrs
      .filter(
        (attr) =>
          ![
            'AgeApparent',
            'AgeApparentConnu',
            'DateDerniereAction',
            'DateDerniereInspection',
            'DateInstallation',
            'FamilleEquipement',
            'id',
            'Identifiant',
          ].includes(attr)
      )
      .map((attr) => ({ label: attr, value: attr }));
    return attributsList;
  };

  const assetClassComponent = useMemo(() => {
    if (assetClassByGisement) return <TextField data-cy="asset-class" value={assetClassByGisement} disabled />;
    else
      return (
        <FormControl>
          <InputLabel id="class-actif">Classe d&apos;actif</InputLabel>
          <Select
            data-cy="asset-class"
            sx={{ minWidth: 200 }}
            label="Classe d'actifs"
            labelId="class-actif"
            value={selectedAssetClass}
            onChange={async (e) => {
              try {
                setLoadingClassActifAttributs(true);
                const { data } = await Api.Datasets.twingraphQuery(organizationId, datasetId, {
                  query: WizardUtils.buildClasseActifQueryString(e.target.value),
                });
                setSelectedAssetClass(e.target.value);
                setSelectedAssetClassAttrsList(data[0].Attrs);
                setSelectedAssetClassNiveauCout(data[0].NiveauCout);
                setLoadingClassActifAttributs(false);
              } catch (error) {
                console.error(error);
                setLoadingClassActifAttributs(false);
              }
            }}
            options={classActifsList}
          >
            {classActifsList?.map((option) => (
              <MenuItem data-cy={option} key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
  }, [assetClassByGisement, classActifsList, datasetId, organizationId, selectedAssetClass]);

  const [activeStep, setActiveStep] = useState(0);
  const [activeTab, setActiveTab] = useState(2);
  const [selectedRegle, setSelectedRegle] = useState('');
  const [selectedSousGisement, setSelectedSousGisement] = useState('');
  const [selectedSequenceAction, setSelectedSequenceAction] = useState('');
  // const [selectedActionImposee, setSelectedActionImposee] = useState('');
  const hasUserWritePermission = userPermissionsOnCurrentScenario.includes(ACL_PERMISSIONS.SCENARIO.WRITE);
  const isCurrentScenarioRunning =
    scenarioData?.state === SCENARIO_RUN_STATE.RUNNING ||
    scenarioData?.state === SCENARIO_RUN_STATE.DATA_INGESTION_IN_PROGRESS;
  const isCurrentScenarioRejected = scenarioData?.validationStatus === SCENARIO_VALIDATION_STATUS.REJECTED;
  const isCurrentScenarioValidated = scenarioData?.validationStatus === SCENARIO_VALIDATION_STATUS.VALIDATED;

  const editMode = !(
    isCurrentScenarioRunning ||
    isCurrentScenarioRejected ||
    isCurrentScenarioValidated ||
    !hasUserWritePermission
  );

  const handleNext = () => {
    if (selectedGisement) {
      if (!Object.keys(technicalPolicyData?.gisements).includes(selectedGisement)) {
        const newGestionActivites = generateGestionActivitesRow();
        setNewTableNamesMapping(scenarioData.id, selectedGisement, newGestionActivites);
        updateGisementData(
          scenarioData.id,
          [{ Attrs: selectedAssetClassAttrsList, NiveauCout: selectedAssetClassNiveauCout }],
          'ClasseActif',
          selectedGisement
        );
      } else if (Object.values(technicalPolicyData?.gisements[selectedGisement]).includes(null)) {
        const selectedGisementTablesMapping = technicalPolicyData?.gisements[selectedGisement].tableNamesMapping;
        getGisementData(selectedGisementTablesMapping, datasetId, scenarioData);
      }
    }

    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleTabChange = (event, newTab) => setActiveTab(newTab);

  const handleGisementChange = (newGisement) => {
    setSelectedGisement(newGisement);
  };
  const columns = [
    {
      field: 'Attribut',
      label: 'Attribut',
      inputType: 'select',
      options: calculateClassActifAttributes(classActifAttrsByGisement ?? selectedAssetClassAttrsList),
    },
    {
      field: 'Operateur',
      label: 'Opérateur',
      inputType: 'select',
      options: [
        { label: 'IN', value: 'IN' },
        { label: '<', value: 'LT' },
        { label: '≤', value: 'LEQ' },
        { label: '>', value: 'GT' },
        { label: '≥', value: 'GEQ' },
      ],
    },
    { field: 'Valeurs', label: 'Valeur', inputType: 'text' },
  ];

  const handleDiscardChanges = useCallback(
    async (event) => {
      if (isDirty) {
        event.stopPropagation();
        const reset = methods.reset;
        const dialogProps = {
          id: 'discard-wizard-changes',
          labels: {
            title: 'Quitter sans sauvegarder ?',
            body: 'Toutes les modifications non sauvegardées seront perdues.',
            button1: "Retourner à l'édition",
            button2: 'Quitter',
          },
        };
        const result = await TwoActionsDialogService.openDialog(dialogProps);
        if (result === 2) {
          reset({});
          setActiveStep(0);
          setActiveTab(2);
          setSelectedGisement('');
          setSelectedRegle('');
          setSelectedSousGisement('');
          setSelectedSequenceAction('');
          // setSelectedActionImposee('');
          handleCloseWizard();
          setGisementOptions(gisementsList);
        }
      } else {
        setActiveStep(0);
        setActiveTab(2);
        setSelectedGisement('');
        setSelectedRegle('');
        setSelectedSousGisement('');
        setSelectedSequenceAction('');
        // setSelectedActionImposee('');
        setGisementOptions(gisementsList);
        handleCloseWizard();
      }
    },
    [gisementsList, handleCloseWizard, isDirty, methods.reset]
  );

  const [isNiveauPriorisationDialogOpen, setNiveauPriorisationDialogOpen] = useState(false);

  return (
    <FormProvider {...methods} key={`form-${scenarioData.id}-wizard`}>
      <div>
        <Dialog
          data-cy="technical-policy-dialog"
          open={isWizardOpen}
          onClose={handleCloseWizard}
          maxWidth="xl"
          disableEscapeKeyDown={true}
          fullWidth
        >
          <Backdrop open={status === 'SAVING'}>
            <CircularProgress data-cy="saving-technical-parameters-loading-spinner" color="inherit" />
            <Typography variant="h4">{labels.sauvegardeEnCours}</Typography>
          </Backdrop>
          <DialogTitle>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
              <Typography variant="h6" sx={{ flexGrow: 1 }}>
                {labels.gestionDesActivitesWizard}
              </Typography>
              <Grid item sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {activeStep >= 1 && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <TextField value={selectedGisement} disabled sx={{ minWidth: 200, marginTop: 2 }}></TextField>
                    <TextField value={assetClassByGisement} disabled sx={{ minWidth: 200, marginTop: 2 }}></TextField>
                  </Box>
                )}
                <Button
                  data-cy="open-niveau-priorisation-dialog-button"
                  onClick={() => setNiveauPriorisationDialogOpen(true)}
                  variant="contained"
                  color="secondary"
                >
                  {labels.niveauDePriorisation}
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent sx={{ width: '100%', mt: 2, height: activeStep === 1 ? '90vh' : 'auto' }}>
            <NiveauPriorisationDialog
              editMode={editMode}
              open={isNiveauPriorisationDialogOpen}
              setOpen={() => setNiveauPriorisationDialogOpen(false)}
              saveData={saveData}
            />
            {activeStep === 0 && (
              <>
                <Grid container sx={{ display: 'flex', alignItems: 'center', gap: 2, paddingTop: 1 }}>
                  <GenericAutoCompleteInput
                    id="gisements-selector"
                    onChange={(e, newGisement) => {
                      handleGisementChange(newGisement);
                    }}
                    value={selectedGisement ?? ''}
                    onNewOption={(newGisement) => {
                      setSelectedGisement(newGisement);
                      setGisementOptions((prevOptions) => [...prevOptions, newGisement]);
                    }}
                    options={gisementOptions}
                    label={'Sélectionner ou créer un gisement'}
                    editMode={editMode}
                  />
                  {assetClassComponent}
                </Grid>
                <Controller
                  name={selectedGisement ?? ''}
                  render={({ field }) => {
                    const { value, onChange } = field;
                    return loadingClassActifAttributs ? (
                      <Skeleton width="100%" animation="wave">
                        <EditableTable columns={[]} rows={[]} onChange={onChange} />
                      </Skeleton>
                    ) : (
                      <EditableTable
                        id="gisement"
                        columns={columns}
                        rows={
                          value?.filter((row) => !Object.values(row).every((cell) => cell == null)) ??
                          technicalPolicyData?.gisements[selectedGisement]?.Gisement ??
                          []
                        }
                        editMode={editMode && !!selectedGisement && (!!assetClassByGisement || !!selectedAssetClass)}
                        onChange={onChange}
                      />
                    );
                  }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                  <Button
                    data-cy="close-technical-policy-dialog-button"
                    onClick={handleDiscardChanges}
                    variant="outlined"
                    color="secondary"
                  >
                    {isDirty ? 'Annuler' : 'Fermer'}
                  </Button>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      data-cy="next-step-button"
                      disabled={
                        !(selectedGisement && (assetClassByGisement || selectedAssetClass)) ||
                        loadingClassActifAttributs
                      }
                      onClick={handleNext}
                      variant="contained"
                      color="primary"
                    >
                      {labels.suivant}
                    </Button>
                    {editMode && (
                      <Button
                        data-cy="save-technical-policy-parameters-button"
                        onClick={saveWizardData}
                        variant="contained"
                        color="primary"
                        disabled={
                          !(selectedGisement && (assetClassByGisement || selectedAssetClass)) ||
                          loadingClassActifAttributs ||
                          !isDirty
                        }
                      >
                        {labels.sauvegarder}
                      </Button>
                    )}
                  </Box>
                </Box>
              </>
            )}
            {activeStep === 1 && (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                  <Tabs value={activeTab} onChange={handleTabChange} centered>
                    <Tab data-cy="sous-gisement" label={labels.SousGisements} />
                    <Tab data-cy="actions-sequence" label={labels.sequencesActions} />
                    <Tab data-cy="rules" label={labels.reglesDeGestion} />
                    {/* <Tab data-cy="imposed-actions-sequence" label={'Actions Imposées'} /> */}
                  </Tabs>
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      marginTop: 2,
                      paddingTop: 1,
                      overflowY: 'auto',
                    }}
                  >
                    {activeTab === 0 && (
                      <SousGisementsTab
                        selectedGisement={selectedGisement}
                        selectedSousGisement={selectedSousGisement}
                        setSelectedSousGisement={setSelectedSousGisement}
                        editMode={editMode}
                      />
                    )}
                    {activeTab === 1 && (
                      <SequencesActionsTab
                        selectedGisement={selectedGisement}
                        selectedSequenceAction={selectedSequenceAction}
                        setSelectedSequenceAction={setSelectedSequenceAction}
                        editMode={editMode}
                      />
                    )}
                    {activeTab === 2 && (
                      <ReglesGestionTab
                        selectedGisement={selectedGisement}
                        selectedRegle={selectedRegle}
                        setSelectedRegle={setSelectedRegle}
                        editMode={editMode}
                      />
                    )}
                    {/* {activeTab === 3 && (
                      <ActionsImposeesTab
                        selectedActionImposee={selectedActionImposee}
                        setSelectedActionImposee={setSelectedActionImposee}
                        selectedGisement={selectedGisement}
                        editMode={editMode}
                      />
                    )} */}
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Button
                        data-cy="close-technical-policy-dialog-button"
                        onClick={handleDiscardChanges}
                        variant="outlined"
                        color="secondary"
                      >
                        {isDirty ? labels.annuler : labels.fermer}
                      </Button>
                    </Box>
                    <Box>
                      {editMode && (
                        <Button
                          data-cy="save-technical-policy-parameters-button"
                          disabled={!isDirty}
                          onClick={saveWizardData}
                          variant="contained"
                          color="primary"
                        >
                          {labels.sauvegarder}
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </DialogContent>
        </Dialog>
      </div>
    </FormProvider>
  );
};
Wizard.propTypes = {
  isWizardOpen: PropTypes.bool,
  handleCloseWizard: PropTypes.func,
};
export default Wizard;
