import { useState } from 'react';

const useWizardState = () => {
  const [selectedFamily, setSelectedFamily] = useState('');
  const [reliabilityParams, setReliabilityParams] = useState({
    mode: 'Weibull',
    forme: '',
    echelle: '',
  });
  const [curvePoints, setCurvePoints] = useState({});

  return {
    selectedFamily,
    setSelectedFamily,
    reliabilityParams,
    setReliabilityParams,
    curvePoints,
    setCurvePoints,
  };
};

export default useWizardState;
